/* App.css */

body, html {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f2f5;
  font-family: Arial, sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 375px; /* Width similar to a mobile device */
  height: 667px; /* Height similar to a mobile device */
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

/* Additional styling for inner components to resemble mobile chat */

.chat-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #000000 ;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.chat-container {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #000000 ;
}

.message {
  max-width: 70%;
  padding: 10px;
  border-radius: 8px;
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.5;
}

.message.user {
  align-self: flex-end;
  background-color: #DFFFD9;
  color: #000000;
}

.message.bot {
  align-self: flex-start;
  background-color: #333333;
  border: 1px solid #333333;
  color: #fff;
}

.message-input {
  display: flex;
  align-items: center;
  padding: 10px;
  
  background-color: #000000 ;
}

.message-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 14px;
}

.message-input button {
  margin-left: 8px;
  padding: 8px 16px;
  background-color: #25D366;
  border: none;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
